@import '../../../../containers/app/goopter-styles/_colors';

.storeFooter {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100vw;
    max-width: 100vw;
    height: 64px;
    box-shadow: 0px -4px 5px -3px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    background-color: white;
    z-index: 1550;
}

.storeFooterShoppingCart {
    background-color: $palette-red !important;
    color: white !important;
    margin: 12px !important;
    position: relative;
    top: -25px;
    font-size: 1.8rem !important;
    width: 58px !important;
    height: 58px !important;
}

.storeActionButtons {
    display: flex;
    max-width: 60%;
    min-width: 114px;
    height: 100%;
    justify-content: flex-end;


    .MuiBadge-root {
        max-width: 50%;
    }
}

.store-footer-checkout-button {
    background-color: $palette-blue;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    width: 100%;
    padding: 1%;
    max-width: 125px;
    text-align: center;
    cursor: pointer;

    .MuiTypography-root {
        font-size: clamp(70%, 3.5vw, 100%);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-width: 100px;
        width: 100%;
        max-width: 125px;
    }
}

.store-footer-checkout-button.store-footer-checkout-button-disabled {
    pointer-events: none;
    background-color: gray;
}

.store-footer-checkout-button.store-footer-checkout-button-hidden {
    display:none;
}

.store-footer-action-button {
    background-color: $palette-red;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 115px;
    font-size: 4vw;

    select {
        width:  100%;
        height: 100%;
    }
}

.store-footer-action-button.widen {
    width: 230px;
}

.action-info-bar {
    position: fixed;
    z-index: 1500;
    bottom: 64px;
    width: 100vw;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 250, 218, 0.98);
    color: $palette-red;
    left: 0px;

    p {
        font-size: 0.7em !important;
    }
}

.storeFooterSelect {
    color: white;
    -webkit-appearance: none;
    border: 0px;
    outline: 0px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    z-index: 999;
    position: absolute;
}

.storeFooterSelectOption {
    font-size: 1rem;
}


.storeFooterSelectIcon {
    right: 2px;
    box-sizing: border-box;
    pointer-events: none;
    position: absolute;

}

.storeFooterOriginalPrice {
    text-decoration: line-through;
    font-size: 0.6em !important;
}

.storeFooterCurrentPrice {
    font-weight: bold !important;
    font-size: 0.9em !important;
}

.storeFooterPrice {
    height: 70%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
}

.info-content {
    color: $x-dark-text;

    span {
        color: $palette-red;
    }
}

.confirm-dine-in-dialog {
    z-index: 3051 !important;
    display: flex;
    justify-content: center;

    >div {
        &:nth-child(3) {
            width: 500px;
        }
    }
}

.select-center {
    display: flex;
    text-align: center;
}