.toast-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999999999 !important; // higher than dialog
    pointer-events: none;

    .toast {
        min-height: 5em !important;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        max-width: 75% !important;
        padding: 16px;
        background: rgba(40, 40, 40, .75);
        border-radius: 5px;
        color: #fff;
        .toast-content {
            word-wrap: break-word;
        }
    }
}
