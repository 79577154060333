$product-card-height: 200px;
$product-card-image-size: 198px;

@media (min-width: 1024px) {
    .storeFooter,
    .action-info-bar,
    .storeHeaderTabBar,
    .store-header-nav-bar,
    .top-category {
        max-width: 1440px;
        min-width: 0px !important;
        left: 50% !important;
        transform: translate(-50%, 0) !important;
    }

    .sign-up-header-bar {
        position: fixed !important;
    }

    .cat-list-holder {
        max-width: 300px !important;
        min-width: 300px !important;
    }

    .category-left-drawer {
        max-width: 300px !important;
        .MuiPaper-root {
            max-width: 300px !important;
            top: 0px;
            left: 0px;
        }
    }

    .productItemLargeImageStyle {
        height: auto !important;
        border: 0px;
        padding: 0px !important;
        width: calc(33% - 1.5rem) !important;

        .storeProduct {
            display: block !important;
        }
        .storeProductImage {
            background-size: contain;
            background-repeat: no-repeat;
            background-color: #f5f5f5;
        }
        .storeProductDesc {
            width: 100% !important;
            height: $product-card-height;
        }
        .product-buttons-container {
            position: absolute;
            bottom: 12px;
            right: 12px;
        }
        .MuiDivider-root {
            display: none;
        }
    }

    .top-category {
        .MuiTypography-root {
            display: flex;
            justify-content: center;
        }
    }

    .product-list {
        position: relative;
        width: 100%;
        padding: 0px 24px 0px 0px;
        
        .storeProductPriceRowContainer {
            width: 100%;
        }

        .product-buttons-container {
            position: absolute;
            bottom: 12px;
            right: 12px;
        }

        .storeProductCategoryHeader {
            margin: 0 0 0.5rem 0.5rem;
        }

        .productItemsClear {
            width: 100%;
            margin-top: 6px;
        }

        .productItemsClear::after {
            content: "";
            content: "";
            display: block;
            clear: both;
        }
        .productItem {
            margin: 0 0 0.5rem 0.5rem;
            width: calc(33% - 1.5rem);
            height: $product-card-height;
            display: inline-block;
            padding: 0px 0px;
            border: 1px solid #f5f5f5;
            cursor: pointer;
            margin-left: 1.5rem;

            &:hover {
                border: 1px solid rgba(0, 0, 0, 0.3);
            }

            hr {
                display: none;
            }

            .storeProduct {
                min-height: $product-card-image-size;
                height: 100%;
                display: flex;
                width: 100%;
                padding: 0;

                .storeProductImage {
                    min-width: $product-card-image-size;
                    min-height: $product-card-image-size;
                    width: $product-card-image-size;
                    height: $product-card-image-size;
                    border-radius: 0px;
                    background-size: cover;
                    display: inline-block;
                }

                .storeProductDesc {
                    vertical-align: top;
                    display: inline-block;
                    width: calc(100% - 198px); // 100% - $product-card-image-size
                    padding: 8px 8px !important;
                }

                .storeProductTitle {
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                .storeProductShortDesc {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
            }
        }
    }
}

