@import "../../../app/goopter-styles/colors";
@import "./store-products-1024.scss";

$large-image-desc-height: 160px;

.storeProduct {
    display: flex;
    padding: 12px;
    min-height: 108px;
    &-groupPurchase{
        height: 135px;
    }
}

.storeProductImage {
    display: inline-block;
    min-width: 84px;
    width: 84px;
    height: 84px;
    border-radius: 5px;
    background-size: cover;
    background-position: center center;
}

.storeProductDesc {
    position: relative;
    padding: 0px 0px 0px 8px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0px;
    word-break: break-word;
}

.storeProductSubDesc {
    line-height: 14px;
}

.storeProductRating {
    line-height: 14px;
}

.thumbUpIcon {
    margin-left: 3px;
    font-size: 10px;
}

.storeProductSmallDesc {
    color: #666;
    display: inline-block !important;
    overflow: hidden;
    margin-right: 5px !important;
    line-height: 14px !important;
}

.storeProductShortDesc {
    color: #666;
    line-height: 1.2em !important;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.storeProductPrice{
    display: flex;
    align-self: flex-start;
}

.storeProductPriceRow {
    line-height: 16px;
}

.storeProductPriceRow-MaxContentWidth {
    width: max-content;
}
.storeProductPriceRowContainer{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &-groupPurchase{
        flex:1;
    }
}

.store-product-price-and-button-container{
    display: flex;
    justify-content: space-between;
}

.storeProductPrice {
    color: #ed2d29;
    font-weight: 400;
    font-size: 1rem;
    margin-right: 5px !important;
    line-height: 18px !important;
    display: inline-block;
}

.storeProductsKeywords {
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    width: 100%;
    max-width: 1440px;
    left: 50%;
    transform: translate(-50%, 0);
}

.storeProductButtonWrap{
    position: relative;
    height: 42px;
    top: 6px;
}
.storeProductButtonWrapProductPage{
    top:0px;
    left:0px;
}

.storeProductButton {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
    padding: 0.5rem;

    &::after {
        content: "";
        position: absolute;
        top: -50%;
        left: -50%;
        width: 64px;
        height: 48px;
    }
}

.clearButton {
    position: relative;
    padding-right: 3px;
    font-size: 1.2em;

    &::after {
        content: "";
        position: absolute;
        top: -50%;
        left: -100%;
        width: 128px;
        height: 64px;
    }
}

.productClickable {
    height: 42px;
    width: 42px;
    padding: 6px;
    top: -6px;
    left: -6px;
    display: inline-block;
    position: absolute;
    z-index: 10;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}

.addProduct {
    background-color: #ed2d29;
    color: white;
}
.addProductSpecialPage{
    top: 0;
    left: 0;
}

.addProductDisabled {
    border: 1px solid gray;
    background-color: gray;
}

.removeProduct {
    color: #ed2d29;
    border: 1px solid #ed2d29;
}

.storeProductButtonDisabled {
    color: gray;
    border: 1px solid gray;
}

.storeProductButtons {
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    align-items: center;
    float: right;
}

.storeProductQty {
    color: #ed2d29;
    width: 32px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position:relative;
}

.storeProductQtyText{
    font-size: 0.9rem !important;
}

.storeProductTitle {
    width: 100%;
    line-height: 1.2em !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.storeProductTitle2 {
    width: 100%;
}

.storeProductCategoryHeader {
    background-color: rgb(247, 248, 250);
    padding: 12px;
    display: flex;
    align-items: center;
    z-index: 9999;
}

.storeProductButtonOptionWrap{
    padding: 6px;
    position: relative;
    left: 6px;
}

.storeProductButtonOption {
    background-color: #ed2d29 !important;
    color: white !important;
    font-size: 0.7em !important;
    padding: 4px 8px !important;
}

.originalPrice {
    line-height: 18px !important;
    color: #343434;
}

.btnProductDisabled {
    background-color: grey !important;
}

.storeProductInfoButton {
    border: 2px solid white;
    border-radius: 100px;
    position: absolute;
    color: #ed2d29;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    z-index: 12;
    top: -3px;
    right: -4px;
    width: 20px !important;
    cursor: pointer;

    &::after {
        content: "";
        position: absolute;
        top: -50%;
        left: -100%;
        width: 128px;
        height: 64px;
    }
}

.closeAvailabilityDialogButton {
    position: absolute;
    top: -57px;
    right: 0px;
    color: white;
    padding: 5px;
    padding-right: 0;
}

.store-products-infinite-loader-container {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    margin-top: -20px;
}

.store-products-infinite-loader-up-container {
    display: flex;
    justify-content: center;
    padding: 10px;
    width: 100%;
}

.store-products-pull-to-refresh-content-container {
    display: flex;
    justify-content: center;
    padding: 2px;
    margin-top: -20px;
}

.refresh-indicator-offset {
    margin-bottom: -5px;
}

.no-more-products-text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: #9e9e9e;
    padding-top: 30px;
}

.list-bottom-height {
    height: 140px;
    width: 100%;
}

.productItem {
    vertical-align: top;
    width: 100%;
    max-width: 100%;
    min-height: 108px;
}

.productItemLargeImageStyle {
    width: 50%;
    max-width: 50%;
    margin: 0;

    .storeProduct {
        padding: 0;
    }
    .storeProductDesc {
        display: block;
        padding: 12px;
        height: $large-image-desc-height !important;
    }
    .storeProductTitle {
        display: -webkit-box;
        -webkit-line-clamp: 2 !important;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .storeProductShortDesc {
        display: -webkit-box;
        -webkit-line-clamp: 2 !important;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .product-buttons-container {
        position: absolute;
        bottom: 12px;
        right: 12px;
    }
}
.product-buttons-container {
    height: 39.656px;
}

.categorized-products-container {
    position: relative;

    .middle-divider {
        height: 100%;
        width: 1px;
        background-color: rgba(0, 0, 0, 0.08);
        position: absolute;
        left: 50%;
        top: 0;
    }
}

.purchase-limit-reached {
    background-color: $palette-red !important;
    opacity: 0.5 !important;
}
