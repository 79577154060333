$palette-gray: #bdbdbd;
$palette-light-gray: #f3f4f5;
$palette-light-gray2: rgb(230, 230, 230);
$palette-dark-gray: #999999;
$palette-darker-gray: #8d8d8d;
$palette-green: #18AEAC;
$palette-light-black: #464245;
$palette-blue: #313359;
$palette-red: #ed2d29;
$palette-yellow: #ffd600;
$palette-dark-purple: rgb(85, 26, 139);
$palette-dark-blue: #30345A;

$x-continue-button: $palette-blue;
$x-light-border: rgba(0, 0, 0, 0.1);
$x-default-visited-link: $palette-dark-purple;
$x-collapse-icon: dodgerblue;
$x-dark-text: #444;
$x-weui-success: #09bb07;
$x-weui-warn: #f43530;
$x-event-navigation-click: $palette-light-gray2;