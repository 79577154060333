.storeHeader {
    padding: 12px;
    // position: fixed;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    transition: transform 0.3s;
}

.storeHeaderBackground {
    position: absolute;
    top: 0px;
    left: 0px;
    background-size: cover;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s;
    will-change: opacity;
}

.storeHeaderBackgroundOverlay {
    z-index: 20;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

.store-header-nav-bar {
    z-index: 57;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    transition: color 0.3s, background-color 0.3s;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 64px;
    align-items: center;
    padding: 12px;
    will-change: color, background-colour;
}

.store-header-nav-bar-stacked {
    z-index: 57;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    transition: color 0.3s, background-color 0.3s;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 64px;
    align-items: center;
    will-change: color, background-colour;
}

.store-header-nav-bar-row{
    z-index: 57;
    transition: color 0.3s, background-color 0.3s;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 64px;
    align-items: center;
    padding: 12px;
    will-change: color, background-colour;
}

.storeHeaderTabBar {
    z-index: 57;
    position: fixed;
    top: 0px;
    left: 0px;
    transition: color 0.3s, background-color 0.3s;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 48px;
    align-items: center;
    padding: 12px;
    will-change: color, background-colour;
}

.store-header-button {
    padding-left: 18px;
    font-size: 1.2rem;
    display: flex;
    cursor: pointer;
}

.storeHeaderEndButtons {
    display: flex;
    align-items: center;
}

.store-header-shopping-cart {
    align-items: center;
    border: 1px solid white;
    padding: 3px 12px;
    border-radius: 6px;
    cursor: pointer;
    .storeHeaderButtonIcon {
        display: flex;
        align-items: center;
        padding-right: 7px;
        font-size: 0.8em;
    }

    .storeHeaderButtonQty {
        font-size: 0.8em;
    }
}

.store-header-button-left {
    padding-left: 5px;
    padding-top: 5px;
    padding-right: 15px;
    display: flex;
    cursor: pointer;
}

.store-header-nav-title {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    .MuiTypography-h6 {
        font-size: 1.1rem !important;
        
    }
}

.storeHeaderCard {
    z-index: 40;
    background-color: white;
    width: 100%;
    border-radius: 3px;
    box-shadow: 3px 3px 5px -1px rgba(0, 0, 0, 0.25);
    position: relative;
    top: 90px;
    opacity: 1;
    transition: opacity 0.3s;
    min-height: 125px;
    cursor: pointer;
}

.storeHeaderTitle {
    display: flex;
    align-items: center;
}

.storeLogo {
    background-size: cover;
    background-position: center center;
    border-radius: 150px;
    width: 96px;
    height: 96px;
    box-shadow: 3px 3px 5px -1px rgba(0, 0, 0, 0.25);
}

.storeLogoContainer {
    margin: 0px 0px 0px 12px;
    position: relative;
    top: -40px;
}

.storeDescription {
    position: relative;
    overflow: hidden;
    padding: 12px;
    flex-grow: 1;
    .storeDescriptionRow {
        display: flex;
        flex-direction: row;
        align-items: center;

        .circle {
            width: 3px;
            height: 3px;
            border-radius: 5px;
            background-color: black;
            margin: 0px 5px;
        }
    }
}

.storeHeaderDiscounts {
    display: flex;
    padding: 12px;
    overflow-x: auto;
    margin-top: -21px;
}

.storeHeaderDiscounts2 {
    display: flex;
    align-items: center;
}

.storeHeaderAnnouncement {
    // display: flex;
    padding: 20px 12px 0px;
    margin-top: -20px;
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .announcementText {
        display: block;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.storeHeaderDiscount {
    margin-right: 8px !important;
    padding: 3px 6px;
    border-radius: 3px;
    white-space: nowrap;
    font-size: 0.8em !important;
}

.storeHeaderFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.storeHeaderOpenStatus {
    padding: 12px;
    margin-top: -21px;
}

.downloadIconButton {
    padding: 3px 4px !important;
    background-color: hsla(0, 0%, 55%, 0.6) !important;
    font-size: 0.55em !important;
    color: white !important;
    border: 1px solid white !important;
    font-weight: initial !important;
    text-transform: initial !important;
    svg {
        fill: white;
    }

    .MuiButton-startIcon {
        margin-right: 4px !important;
        *:first-child {
            font-size: 18px;
        }
    }
}

.branchOpenAppButton {
    padding: 3px 4px !important;
    position: absolute !important;
    top: 10px;
    left: 10px;
    font-weight: initial !important;
    text-transform: initial !important;
    border: 1px solid rgba(0, 0, 0, 0.5) !important;
    font-size: 0.55em !important;

    color: black !important;
    .MuiButton-startIcon {
        margin-right: 4px !important;
        *:first-child {
            font-size: 18px;
            color: black !important;
        }
    }
}

.StoreMenu--select {
    display: flex;
    min-height: 24px;
    align-items: center;
    padding: 12px;
    justify-content: space-between;
    text-transform: uppercase;
    font-weight: bold;
    width: 100%;

    .StoreMenu--select-button {
        display: flex;
        flex-grow: 1;
    }
    .StoreMenu--selected-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 6px;
    }

    .StoreMenu--search-icon {
        padding-right: 12px;
    }
}

.StoreMenu--drawer {
    z-index: 20000 !important;

    .StoreMenu--drawer-header {
        padding: 16px;
        border: 1px solid rgba(0, 0, 0, 0.1);
    }

    .StoreMenu--drawer-check {
        color: rgb(60, 133, 108);
    }
}
