@import 'flex.scss';
@import 'flex-class.scss';
@import 'weui.scss';
@import '../store-page/page-components/store-products/store-products.scss';
@import '../store-page/page-components/store-reviews-tab/store-reviews-tab.scss';
@import '../store-page/page-components/store-header/store-header.scss';
@import '../store-page/page-components/store-footer/store-footer.scss';
@import '../app/goopter-styles/colors';

//fix safari Viewport Units Buggyfill bug
img {
  content: normal !important;
}

// text-align related
.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.word-break-break-all {
  word-break: break-all;
  word-wrap: break-word;
}

.word-wrap-break-word {
  display: initial !important;
  word-wrap: break-word;
}

.frosted-glass {
  background: inherit;
  overflow: hidden;
  background-color: rgba(255, 255, 255, .85);  
  backdrop-filter: blur(5px);
}

.MuiTabs-flexContainer {
  height: 100%;
}

.clearIcon {
  fill: #80868b !important;
}

.spacedBorder {
  background-image: linear-gradient(to right, rgb(212, 212, 212) 20%, rgba(170, 170, 170,0) 0%);
  background-position: bottom;
  background-size: 10px 2px;
  background-repeat: repeat-x;
}

.spacing-4 {
  height: 4px;
  width: 100%;
}

.spacing-8 {
  height: 8px;
  width: 100%;
}

.spacing-12 {
  height: 12px;
  width: 100%;
}

.spacing-16 {
  height: 16px;
  width: 100%;
}

.spacing-32 {
  height: 32px;
  width: 100%;
}

.h-spacing-4 {
  display: inline-block;
  width: 4px;
  height: 100%;
}

.h-spacing-8 {
  display: inline-block;
  width: 8px;
  height: 100%;
}

.h-spacing-12 {
  display: inline-block;
  width: 12px;
  height: 100%;
}

.h-spacing-16 {
  display: inline-block;
  width: 16px;
  height: 100%;
}

.h100 {
  height: 100%;
}

.align-items-center {
  display: flex;
  align-items: center;
}

.justify-content-center {
  display: flex;
  justify-content: center;
}

.flex-end-jc {
  display: flex;
  justify-content: flex-end;
}

.text-overflow-ellipsis-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.text-overflow-ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.text-overflow-ellipsis-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.red {
  color: $palette-red;
}

.bold {
  font-weight: bold;
}
