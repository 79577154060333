.store-review-tab--header {
    display: flex;
    padding: 12px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    border-top: 1px solid rgba(0,0,0,0.1);
    align-items: center;
    background-color: white;
    max-height: 51px;
}

.store-review-tab--write-review-button {
    border: 1px solid #ed2d29 !important;
    color: #ed2d29 !important;
    background-color: white !important;
    font-size: 0.7em !important;
}

.store-review-tab--review-summary {
    display: flex;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    background-color: white;
}

.store-review-tab--review-overall {
    display: flex;
    flex-direction: column;
    padding: 12px;
    align-items: center;
    justify-content: center;
    min-width: 96px;
    min-height: 88px;
    border-right: 1px solid rgba(0,0,0,0.1);;
}

.store-review-tab--review-averages {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-size: 0.8em;
    justify-content: center;
}

.store-review-tab--review-average {
    padding: 12px;
    display: flex;
}

.store-review-tab--product-review {
    padding: 6px 6px 6px 0px;
    margin-top: 6px;
    border-radius: 3px;
}

.store-review-tab--align-center {
    display: flex;
    align-items: center;
}

.store-review-tab--align-start {
    display: flex;
    align-items: flex-start;
}


.store-review-tab--product-rating {
    padding-right: 12px;
    font-size: 1.6em;
    color: #ffb400;
}

.store-review-tab--product-comments {
    display: flex;
    padding-top: 3px;
    padding-left: 34.39px;
    max-width: 100%;
    overflow-y:auto;
}

.store-review--overflow-hidden-container {
    overflow: hidden;
    max-width: 100%;
}

.store-review-tab--product-comment {
    background-color: #e4f2ff;
    padding: 3px 6px;
    border-radius: 3px;
    font-size: 0.8em;
    margin-right: 6px;
    white-space: nowrap;
}

.store-review-tab--review {
    padding: 12px;
    background-color: white;
    align-items: flex-start !important;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}

.store-review-tab--product-name {
    color: #78aecd;
}